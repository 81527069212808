import { intersectionObserve } from "./_intersection-observer";

const boxOne = document.querySelectorAll('.box.one');

intersectionObserve(boxOne, -300, 0, (element, intersectionRatio) => {
    (function(el) {
        if (intersectionRatio > 0 && !el.classList.contains('show')) {
            el.classList.add('show');
        }
    })(element);
});

const twoBox = document.querySelectorAll('.box.two');

intersectionObserve(twoBox, -300, 0, (element, intersectionRatio) => {
    (function(el) {
        if (intersectionRatio > 0 && !el.classList.contains('show')) {
            setTimeout(() => {
                el.classList.add('show');
            }, 400);
        } 
    })(element);
});

const threeBox = document.querySelectorAll('.box.three');

intersectionObserve(threeBox, -300, 0, (element, intersectionRatio) => {
    (function(el) {
        if (intersectionRatio > 0 && !el.classList.contains('show')) {
            setTimeout(() => {
                el.classList.add('show');
            }, 200);
        } 
    })(element);
});

const fourBox = document.querySelectorAll('.box.four');

intersectionObserve(fourBox, -300, 0, (element, intersectionRatio) => {
    (function(el) {
        if (intersectionRatio > 0 && !el.classList.contains('show')) {
            setTimeout(() => {
                el.classList.add('show');
            }, 500);
        } 
    })(element);
});

const text = document.querySelectorAll('.bottom-text');

intersectionObserve(text, -350, 0, (element, intersectionRatio) => {
    (function(el) {
        if (intersectionRatio > 0 && !el.classList.contains('show')) {
            el.classList.add('show');
        } 
    })(element);
});
