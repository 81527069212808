let searchIcon = document.querySelector('header button.search-btn');
let searchPage = document.querySelector('body.search');

if (searchIcon) {
    let search = document.querySelector('#search');
    let searchInput = document.querySelector('#search #search-input');
    searchIcon.addEventListener('click', function (e) {
        e.preventDefault();
        if(search.classList.contains('expand')) {
            search.classList.remove('expand');
        }
        else {
            search.classList.add('expand');
            searchInput.focus();
        }
    });
}

if (searchPage) {
    let pageSearchInput = searchPage.querySelector('#search-page #search-page-input');
    let inputValue = searchPage.querySelector('#content-section #search-page #search-page-input').value;
    pageSearchInput.focus();
    pageSearchInput.value = '';
    pageSearchInput.value = inputValue;
}
