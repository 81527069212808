import Glide from "@glidejs/glide";
let glide = null;
let container = document.querySelector('.case-study-container .glide__track');
let skrewSliderVideo = document.querySelectorAll('.case-study-slider-video');

function initCaseStudySlider(id) {
    glide = new Glide('#' + id, {
        type: 'carousel',
        rewind: false, 
        bound: true,
        startAt: 0,
        gap: 24,
        perView: 1,
        breakpoints: {
            900: {
                perView: 1,
                gap: 24,
            }
        }
    });
    
    glide.mount();    
}

export function moveGlide(whichWay) {
    glide.go(whichWay);
}


window.initCaseStudySlider = initCaseStudySlider;