import "./_body-view-height";
import "./_body-view-width";
import "./_header-height";
import "./header";
import "./_search";
//import './_lazy-image.js';
//import './_slideshow.js';
import "./_simple-image-slider-configuration.js";
import "./_simple-image-slider.js";
import "./_case-study-slider-configuration.js";
import "./_case-study-slider.js";
import "./_text-with-graphics.js";
import { intersectionObserve } from "./_intersection-observer";

let skipHeader = document.querySelector("a.visually-hidden.skip-to-main");

if (skipHeader != undefined) {
	skipHeader.addEventListener("focusin", function (e) {
		this.classList.add("focused");
	});
	skipHeader.addEventListener("focusout", function (e) {
		this.classList.remove("focused");
	});
}

function getPosts() {
	let data = new FormData();
	data.append("action", "get_posts");

	$.ajax({
		url: project_scripts.ajax_url,
		type: "POST",
		data: data,
		cache: false,
		processData: false,
		contentType: false,
		error: function (data) {
			console.error(data);
		},
		complete: function (data) {
			console.log(data.responseText);
		},
	});
}